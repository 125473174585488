const loadGoogleTagManager = async () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    "gtm.start": new Date().getTime(),
    event: "gtm.js",
  });
  const script = document.createElement("script");
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtm.js?id=${process.env.REACT_APP_GOOGLE_TAG_MANAGER_KEY}&l=dataLayer`;
  document.head.appendChild(script);
};

export default loadGoogleTagManager;

export const gtmAddLayer = (layerObject: object) => {
  if ("dataLayer" in window) {
    window.dataLayer.push(layerObject);
  }
};

export const gtmSetId = (target: HTMLElement) => {
  window.dataLayer = window.dataLayer || [];
  try {
    if ("dataLayer" in window) {
      const id = target.dataset["gtmId"] || target.id;
      id && window.dataLayer.push({
        event: "gtm.click",
        "gtm.element": target,
        "gtm.elementClasses": target.className,
        "gtm.elementId": target.dataset["gtmId"] || target.id,
        "gtm.elementTarget": "",
        "gtm.elementUrl": "",
      });
    }
  } catch (e) {
    // console.error("gtmSetId: ", e);
  }
};
