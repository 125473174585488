import { 
  User
} from "../../models/User";
import { 
  TcampaignActions,
  IcampaignUrlParams,
  IcampaignRecord
} from "../../models/Campaigns";
import { CampaignsAPI } from '../services/axios';
import moment from 'moment';



/**
 * This screen get via url the campaign information, saves a track record in localStorage and apply actions
 * Expected url params:
 * +---------------------------------------------------------------------------------------------------------------------------------------------------------------------+
 * |     Key      |             type            | required |         default         |                                    description                                    | 
 * +---------------------------------------------------------------------------------------------------------------------------------------------------------------------+
 * | campaignId   | string                      | true     |                         | Campaign Id                                                                       |
 * +---------------------------------------------------------------------------------------------------------------------------------------------------------------------+
 * | action       | refers to valid actions     | true     |                         | The action espected to be perfomed by the user                                    |
 * +---------------------------------------------------------------------------------------------------------------------------------------------------------------------+
 * | redirectUrl  | string                      | false    | window.location.origin  | Url where the user must be redirected                                             |
 * +---------------------------------------------------------------------------------------------------------------------------------------------------------------------+
 * | source       | string                      | false    |                         | Channel where the referrer url come from                                          |
 * +---------------------------------------------------------------------------------------------------------------------------------------------------------------------+
 * | userId       | string                      | false    |                         | The Id that identifies the user in the campaign                                   |
 * +---------------------------------------------------------------------------------------------------------------------------------------------------------------------+
 * | validTime    | string                      | true     | 72                      | Amount of hours that the token will be valid                                      |
 * +---------------------------------------------------------------------------------------------------------------------------------------------------------------------+
 * | expireDate   | string                      | false    |                         | Expire date of the campaign in DD-MM-YYYY format                                  |
 * +---------------------------------------------------------------------------------------------------------------------------------------------------------------------+
 * |              |                             |          |                         | Field for aditional data that needs to be tracked, but is not always              |
 * | payload      | string                      | false    |                         | Data must be formated on a string, separated by commas, each separated value      |
 * |              |                             |          |                         | must be separated with a "-", for example -> key1-value1,key2-value2,key3-value3  |
 * +---------------------------------------------------------------------------------------------------------------------------------------------------------------------+
 */
/**
 * Actions reference
 * +----------------------------------------------------------------------------------------------------------------+
 * | Action Key |     Expected Action     | Acceptance Criteria                                                     ||
 * +----------------------------------------------------------------------------------------------------------------+
 * | login      | User login into Carker  | The users login before the validDateTime in the eventRecord is reached  |
 * +----------------------------------------------------------------------------------------------------------------+
 * | signup     | User signup into Carker | The users signup before the validDateTime in the eventRecord is reached |
 * +----------------------------------------------------------------------------------------------------------------+
 */
/**
 * Record set reference
 * +---------------------------------------------------------------------------------------------------------------------------------------------------------------------+
 * |        Key         |       type       | required |         default         |                                    description                                         | 
 * +---------------------------------------------------------------------------------------------------------------------------------------------------------------------+
 * | campaignId         | string           | true     |                         | Campaign Id                                                                            |
 * +---------------------------------------------------------------------------------------------------------------------------------------------------------------------+
 * | action             | TcampaignActions | true     |                         | The action espected to be perfomed by the user                                         |
 * +---------------------------------------------------------------------------------------------------------------------------------------------------------------------+
 * | redirectUrl        | string           | true     | window.location.origin  | Url where the user must be redirected, including url params                            |
 * +---------------------------------------------------------------------------------------------------------------------------------------------------------------------+
 * | validTime          | number           | true     | 72                      | Amount of hours that the token will be valid                                           |
 * +---------------------------------------------------------------------------------------------------------------------------------------------------------------------+
 * | validTimeDateTime  | number           | true     | currentDate + 72 hours  | Datetime until the token will be valid in unix format                                  |
 * +---------------------------------------------------------------------------------------------------------------------------------------------------------------------+
 * | source             | string           | false    |                         | Channel where the referrer url come from                                               |
 * +---------------------------------------------------------------------------------------------------------------------------------------------------------------------+
 * | userId             | string           | false    |                         | The Id that identifies the user in the campaign                                        |
 * +---------------------------------------------------------------------------------------------------------------------------------------------------------------------+
 * | expireDate         | string           | false    |                         | Expire date of the campaign in DD-MM-YYYY format                                       |
 * +---------------------------------------------------------------------------------------------------------------------------------------------------------------------+
 * | expireDateTime     | number           | false    |                         | Expire date time of the campaign in unix format                                        |
 * +---------------------------------------------------------------------------------------------------------------------------------------------------------------------+
 * |                    |                  |          |                         | Field for aditional data that needs to be tracked, but is not always                   |
 * | payload            | string           | false    |                         | Data must be formated on a string, separated by commas, each separated value           |
 * |                    |                  |          |                         | must be separated with a "-", for example -> key1-value1,key2-value2,key3-value3       |
 * +---------------------------------------------------------------------------------------------------------------------------------------------------------------------+
 * | campaignTrackingId | number           | false    |                         | Id for tracking returned by the backend when the record request is sent to the backend |
 * +---------------------------------------------------------------------------------------------------------------------------------------------------------------------+
 * | endConsumerId      | string           | false    |                         | The id that identifies the user in CarKer                                              |
 * +---------------------------------------------------------------------------------------------------------------------------------------------------------------------+
 * | endConsumerEmail   | string           | false    |                         | The email asociated with the user id in CarKer when the steps expecteds are completed  |
 * +---------------------------------------------------------------------------------------------------------------------------------------------------------------------+
 */


class CampaignsHandler {

  validActions = ["login", "signup"];
  expireDateFormat = "DD-MM-YYYY";
  localRecordKey = "campaignTrack";
  blackList = [
    "pihey.com",
    "pihey", 
    "nefyp.com",
    "nefyp",
    "disroot.org",
    "disroot",
    "kygur.com",
    "kygur",
    "tijux.com",
    "tijux",
    "dragonhospital.net", 
    "dragonhospital", 
    "jyplo.com", 
    "jyplo", 
    "khig.site",
    "khig",
    "myxu.info",
    "myxu",
    "tigpe.com",
    "tigpe",
    "xuge.life",
    "xuge",
    "yutep.com",
    "yutep",
    "hotmai.com",
    "ihgu.info",
    "ihgu",
    "cguf.site",
    "cguf"
  ];

  whiteList = [
    "@hotmail.es",
    "@hotmail.com",
    "@gmail.com",
    "@yahoo.es",
    "@yahoo.com",
    "@outlook.com",
    "@outlook.es",
    "@icloud.com",
    "@live.com.mx",
    "@live.com"
  ];

  constructor(){};

  private isValidAction(intendedAction:string | null):boolean {
    if (intendedAction === null) return false;
    if (!!intendedAction === false) return false;
    return this.validActions.indexOf(intendedAction) !== -1;
  }

  // Request to the backend the creation of the token record
  private async setRemoteRecord (record: IcampaignRecord): Promise<IcampaignRecord> {
    const newRecord = await CampaignsAPI.set({
      ...record,
      validDateTime: moment.unix(record.validDateTime as number).toISOString(),
      ...(record.expireDateTime && {expireDateTime: moment.unix(record.expireDateTime as number).toISOString()}),
      ...(record.payload && {payload: JSON.stringify(record.payload)})
    });
    return newRecord.data;
  }

  // Tell the backend that user completed the action
  private async completeRemoteRecord (record: IcampaignRecord): Promise<IcampaignRecord> {
    const updateRecord = await CampaignsAPI.update({
      campaignTrackingId: record.campaignTrackingId,
      endConsumerEmail: record.endConsumerEmail!,
      endConsumerId: record.endConsumerId!
    });
    this.deleteLocalRecord();
    return updateRecord.data;
  }

  // Get token record from localhost
  private getLocalRecord(): IcampaignRecord | null {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(this.localRecordKey);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : null; 
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return null;
    }
  }

  // set token record in localhost
  private setLocalRecord(record: IcampaignRecord) {
    try {
      // Save to local storage
      if (typeof window !== "undefined") {
        window.localStorage.setItem(this.localRecordKey, JSON.stringify(record));
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  }

  // delete token record in localhost
  private deleteLocalRecord() {
    try {
      // Save to local storage
      if (typeof window !== "undefined") {
        window.localStorage.removeItem(this.localRecordKey);
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  }

  /**
   * This function check if any record is created in localStorage
   * If a localStorage record exists, we check if match with the performed action
   */
  public async isActionCompleted(action: TcampaignActions, user: User): Promise<boolean> {
    const storedRecord = this.getLocalRecord();
    if (storedRecord === null) return false;

    if (storedRecord.action !== action) return false;

    const currentDateTime = moment();
    const validDateTime = moment.unix(storedRecord.validDateTime as number);

    // if currentDateTime is after validaDateTime, delete record and return false
    if (currentDateTime.isAfter(validDateTime)) {
      this.deleteLocalRecord();
      return false;
    }

    // if expireDateTime is configured and currentDate is after expireDateTime, delete record and return false
    if (storedRecord.expireDateTime !== undefined) {
      const expireDateTime = moment.unix(storedRecord.expireDateTime as number);
      if (currentDateTime.isAfter(expireDateTime)) {
        this.deleteLocalRecord();
        return false;
      }
    }

    // Check if the email is black listed
    const isBlackListed = this.blackList.some(dom => user.email.includes(dom));
    // Is blacklisted, give him a so sorry message
    if (isBlackListed === true) {
      console.error('I\'m so sorry, we are working on fixing this issue. ;)');
      this.deleteLocalRecord();
      return false;
    }

    const isWhiteListed = this.whiteList.some(dom => user.email.includes(dom));
    // Is blacklisted, give him a so sorry message
    if (isWhiteListed === false) {
      console.error('I\'m so sorry, we are working on fixing this issue. ;)');
      this.deleteLocalRecord();
      return false;
    }

    // If you are here, everything went good and the user deserves a great reward
    // If a reward you want to give, to the backend you must talk, says Master Joda
    this.completeRemoteRecord({
      ...storedRecord,
      endConsumerId: user.id,
      endConsumerEmail: user.email
    });
    
    return true;
  }

  /**
   * This function receives the url params that configure the campaign record
   * Parse the data and creates a new record in local and backend
   * Local record is needed to let know to the app that we are waiting for the user to perform an action to give him a reward, as part of a promotional campaign
   * Backend record is needed to get anaytics of opened links and to have a control of the users that completed the action needed to get the reward
   * @param params 
   * @returns 
   */
  public async handleUrlParams(params: IcampaignUrlParams) {
    // Parse params and set defaults
    const record: IcampaignRecord = {
      campaignTrackingId: 0,
      validDateTime: 0,
      ...(params.campaignId && {campaignId: params.campaignId}),
      ...(params.action && this.isValidAction(params.action) && {action: params.action as TcampaignActions}),
      ...(params.redirectUrl ? {redirectUrl: params.redirectUrl} : {redirectUrl: window.location.origin}),
      ...((params.validTime && isNaN(parseInt(params.validTime)) === false) && parseInt(params.validTime) > 0 ? {validTime: parseInt(params.validTime)} : {validTime: 72}),
      ...(params.source && {source: params.source}),
      ...(params.userId && {userId: params.userId}),
      ...(params.expireDate && {expireDate: params.expireDate})
    }
    
    // Check if campaign id is provided, if isn't provided, we cannot continue
    if (record.campaignId === undefined) return record;

    // Check if action is provided and is valid, if isn't provided, we cannot continue
    if (record.action === undefined) return record;

    // Check if expiry date is provided and campaign is not overdue
    if (record.expireDate !== undefined) {
      const currentDateTime = moment();
      const expireDateTime = moment(params.expireDate, "DD-MM-YYYY");
      // if currentDate is after expireDate, return the record
      if (currentDateTime.isAfter(expireDateTime)) {
        return record;
      } else {
        record.expireDateTime = expireDateTime.unix();
      };
    }
    
    // From now on, the record must be set before return
    // Create datetime until the track record will be valid
    record.validDateTime = moment().add(record.validTime, "hours").unix();

    // Prepare redirect url params
    record.redirectUrl += `?action=${record.action}`;

    // Parse payload if is provided
    if (typeof params.payload === 'string' && Array.isArray(params.payload.split(";"))) {
      const payload = params.payload.split(";");
      const parsedPayload = payload.reduce((previous, current ) => {
        const parsed = current.split('=');
        const newObject = {...previous}
        if (parsed.length === 2) {
          newObject[parsed[0]] = parsed[1];
        }
        return newObject;
      }, {});
      record.payload = parsedPayload;
    }

    // Create record in backend
    const backendRecord = await this.setRemoteRecord(record);

    // Create record in localStorage
    this.setLocalRecord({
      ...record,
      campaignTrackingId: backendRecord.campaignTrackingId
    });

    return record;
  }
}

export default CampaignsHandler;
