type TOutput = 'tablet' | 'mobile' | 'desktop'
const deviceType = () : TOutput => {
  try {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return "tablet";
    }
    else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
        return "mobile";
    }
    return "desktop";
  }
  catch(e) {
    return "desktop";
  }
  
};

export default deviceType;
