import { useLayoutEffect, useRef, useCallback } from 'react';

function useResizeObserver<T extends HTMLElement>(
  callback: (target: T, entry: ResizeObserverEntry) => void
): React.MutableRefObject<T | null> {
  const ref = useRef<T>(null);

  // Debounce the callback to avoid infinite resize loops
  const debounce = (func: Function, wait: number) => {
    let timeout: ReturnType<typeof setTimeout>;
    return (...args: any[]) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  };

  const debouncedCallback = useCallback(
    debounce((target: T, entry: ResizeObserverEntry) => {
      callback(target, entry);
    }, 100),
    [callback]
  );

  useLayoutEffect(() => {
    const element = ref.current;

    if (!element) {
      return;
    }

    const observer = new ResizeObserver((entries) => {
      if (entries[0]) {
        debouncedCallback(element, entries[0]);
      }
    });

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [debouncedCallback]);

  return ref;
}

export default useResizeObserver;
