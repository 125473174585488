/**
 * Constructs a full URL for an image asset based on the provided file URL fragment.
 * The base URL is retrieved from the environment variable REACT_APP_BASE_URL.
 * 
 * @param {string} [fileUrl=""] - The specific file path or identifier for the image asset.
 *                               This should be a relative path excluding the base URL and
 *                               the leading assets directory path.
 * @returns {string} - The complete URL string pointing to the image asset on the server.
 */
export const getImageBaseUrl = (fileUrl: string = ""): string =>
  `${process.env.REACT_APP_BASE_URL}/assets/app-images/${fileUrl}`;
