import moment from "moment";

interface IParsedToken {
  sv: string;
  ss: string;
  srt: string;
  spr: string;
  st: moment.Moment | null; // Issued datetime
  se: moment.Moment | null; // Expiration datetime
  sp: string;
  sig: string;
}
interface IValidationResponse { 
  isValid: boolean;
  parsedToken: IParsedToken;
  validTime: number;
}

/**
 * This class handles basic operations to parse and validate sasTokens
 */
class SasTokenHandler {

  token: string = '';
  parsedToken: IParsedToken = {
    sv: '',
    ss: '',
    srt: '',
    spr: '',
    st: null,
    se: null,
    sp: '',
    sig: ''
  }
  errorMessage: string = '';
  isValid: boolean = false;
  validTime: number = 0;

  constructor(token?: string){
    this.token = token || '';
    this.parseToken();
  };

  public setDefaults = () => {
    this.parsedToken = {
      sv: '',
      ss: '',
      srt: '',
      spr: '',
      st: null,
      se: null,
      sp: '',
      sig: ''
    }
    this.errorMessage = '';
    this.isValid = false;
    this.validTime = 0;
  }

  /**
   * This operation receives a token and compare it with the previous token
   * If they are different, a new token is setted
   * Else, new token is ignored
   * returns a boolean that indicate if token is valid
   */
  public setToken = (token: string): IValidationResponse => {
    // Check if new token is equals to the previous one, and replace it.
    if (token !== this.token) this.token = token;
    return this.parseToken();
  }

  public parseToken = () : IValidationResponse => {
    if (this.token === '') {
      this.setDefaults();
    } else {
      const parsedTokenObj = new URLSearchParams(this.token);
      this.parsedToken = {
        sv: parsedTokenObj.get('sv') || '',
        ss: parsedTokenObj.get('ss') || '',
        srt: parsedTokenObj.get('srt') || '',
        spr: parsedTokenObj.get('spr') || '',
        st: parsedTokenObj.get('st') !== null ? moment(parsedTokenObj.get('st')) : null,
        se: parsedTokenObj.get('se') !== null ? moment(parsedTokenObj.get('se')) : null,
        sp: parsedTokenObj.get('sp') || '',
        sig: parsedTokenObj.get('sig') || ''
      }
    }
    return {
      ...this.validateToken(),
      parsedToken: this.parsedToken
    };
  }

  public validateToken = (): {
    isValid: boolean;
    validTime: number;
  } => {
    if (this.token === '') {
      this.errorMessage = 'Token cannot by empty/falsy';
      this.isValid = false;
      this.validTime = 0;
    } 
    if (this.parsedToken.se === null) {
      this.errorMessage = 'Expired date not defined, token malformed.';
      this.isValid = false;
      this.validTime = 0;
    }
    if (this.parsedToken.se !== null) {
      const timeDiff = this.parsedToken.se.diff(moment());
      this.validTime = timeDiff;
      this.isValid = timeDiff > 0;
      this.errorMessage = '';
    }
    return {
      isValid: this.isValid,
      validTime: this.validTime
    };
  }

}
export default SasTokenHandler;
