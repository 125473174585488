class localStorage {

	/**
	 * 
	 * @param key string key that will be used to set/get stored value in localStorage
	 * @param initialValue value to be used if there is no stored value in localStorage with the given key, by default is undefined
	 * @param forceInitial if is true, constructor will ignore any previous storedValue and will initialize with initialValue
	 */
	constructor(private key: string, private initialValue: any = undefined, forceInitial: boolean = false) {
		if (forceInitial) {
			this.initialValue = initialValue;
		} else {
			// Set initialValue if is already setted on localStorage
			try {
				const item = window.localStorage.getItem(key);
				// Parse stored json merged with initial value, if none return initialValue
				this.initialValue = item ?  {
					...this.initialValue,
					...JSON.parse(item)
				} : this.initialValue;
			} catch (error) {
				// If error also return initialValue
				console.error(error);
				this.initialValue = initialValue;
			}
		}
		
	}
	get() {
		try {
			// Get from local storage by key
			const item = window.localStorage.getItem(this.key);
			// Parse stored json merged with initial value, if none return initialValue
			return item ?  {
				...this.initialValue,
				...JSON.parse(item)
			} : this.initialValue;
		} catch (error) {
			// If error also return initialValue
			console.error(error);
			return this.initialValue;
		}
	}
	set(value: any) {
		try {
			// Save to local storage
			if (typeof window !== "undefined") {
				window.localStorage.setItem(this.key, JSON.stringify(value));
			}
		} catch (error) {
			// A more advanced implementation would handle the error case
			console.log(error);
		}
	}
}

export default localStorage;