// loadableHelper.js
import React from 'react';
import loadable from '@loadable/component';
import { WheelLoader } from '../components/Loaders';

const loadableComponent = (importFunc, opts = {}) => loadable(importFunc, {
  fallback: <WheelLoader />,
  ...opts,
});

export default loadableComponent;
